import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import Directories from "../components/Home/Directories";
import FormHome from "../components/Home/FormHome";
import Map from "../components/Contact/MapContent";
import Modal from "../components/Home/Modal";
import BaseLayout from "../components/global/BaseLayout";
import CounterUp from "../components/global/CounterUp";
import ServicesHome from "../components/Home/ContentServicesHome";
import HeroSection from "../components/Home/HeroSection";
import Paletacolor from "../components/global/Paletacolor";
import BlockPrincipal from "../components/block/Block_6";
import BlockSecondary from "../components/block/Block_4";
import BlockAbout from "../components/block/Block_1";
import VideoPromocional from "../components/global/VideoPromocional";
import ContentServices from "../components/Services/ContentServices";
import GalleryContent from "../components/Gallery/GalleryContent";

// para agregar la frase de los años de experiencia o la licencia agregar sloganPrincipal={true}
// para agregar la lista de about agregar listsAbout={true}
// para agregar la lista de servicios agregar listsServices={true}

function Home() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <BaseLayout PageName="Home">
      <div className="md:max-w-full w-full">

        <Modal />

        <HeroSection />


        <BlockPrincipal
          text={rpdata?.dbHome?.[0].text}
          image1={rpdata?.stock?.[10]}
          image2={rpdata?.stock?.[6]}
          image3={rpdata?.stock?.[7]}
          image4={rpdata?.stock?.[8]}
          sloganPrincipal={true}
          listsServices={true}
        />

        {/* video promocional */}
        {
          rpdata?.videosPromo?.[0]?.activo === false ?
            <VideoPromocional
              title={rpdata?.dbSlogan?.[2]?.slogan}
              text={rpdata?.dbAbout?.[1]?.text}
              linkVideo={`${rpdata?.videosPromo?.[0].link}`}
              image={`${rpdata?.videosPromo?.[0].img}`}
            />
            : null
        }

        <div className="flex justify-center w-[60%] mx-auto flex-wrap">
        <img src="https://fffe4c31a4d23af68538722aeec9a327bf7376db09cf94156433891-apidata.googleusercontent.com/download/storage/v1/b/searchapp-25415.appspot.com/o/EMMA-LANDSCAPING-coupon-2.png?jk=AahUMluiuvCv2s4nMb-dO6mcAckl_zp7NHHwSN5gkM-m2BhSEOdiClmiFDKXXkLE97qPlY6qFM7k1Gj7Ll0J-c9suT9ptuBXJORNOfpp-u9Y8eSDqj7kvX1vDHUahCF4zY3g4zW3XpxTUgEw6JKhNIWoC7K9OFxy2b6rVXgx__brg-UrW5PoM1i6WvPsrV8dOI18kjdruvFwl9gcP5wOG-1L3Dlni3tFnBFd2EV71wN71c06xXoRrWkPus7EDKAJgLUufPDydlj51YdfedASVZs9SwTKAF_KkamPpAZD4ZeQfoiDJSzlrTqM77rKYs1r5GJi6XVgepY8BjWpWGWuTkjPuhVIoiXcFAkYwdu3ia-OhghXpO_J2wWm8rjPkIXrYILDT8FvntkbY_uausQPrdJzNpC8lr_Lyr7MmDYL0UIv-COAg_0ZK7sxWfajqrVuoJXv7OwZarwwh5_d9f3s7-ph71Tpg0_weyKcmuFP1MHKKP-AoCVx5-6IL2cGHHC6OEA5JsF7OG8elgDMy3WACMlTTu9AHECcYow5RnLSEqM6ZunDK_mQtjl4NKc3mWy8h8eZ-DKxFI569TiglygLIac_xkm0fGIX4gBRrQl3zHHdojm8KTHQlOYiiDfGbXoruTKIl1UyBITQXf9ILFxJaZcOOET4LSajHvXrexlr1HtY7m5csOcfmJqIAV_kCfNJFtGMCXtQb7q-28nHBbyRtDbmHQJTd0v-6ym8gVoDLwbvvfcqoW1ZrNtQ2asHbCgm1EiEOI3Tn1A8lHtPUXoXD47p5C8uwkXAGYudymKs7YBAOt-8IwGwTQyrqYRJXalB-1yBPBw3IGAfcfucj99KxAfcCKs7doAsES5tNbtlFMUugbvkDho-j_x9JcTj1rSernbQaxgxi8yeluRiNudUG1WhLkSJDsH9zHwv4JLjF6CUEPahfudYdMUSnXmKYcF1a_YqNa8aRMNWsAWVHhAzPNCtKsL0OrGd43Zi_D7Cv7LAgZrWfLeMrmZt78kriQ6SkiQo4OrKXW1XuEgRkuBXcFiMuYQMvYkVPTHlAQ5LXVbZnoI6eCtpN-qsaKemtPcLgK13tqfVGzLhMaEH4mHGHxaV5Xi3fMMgoVvwKhYCieRBujKvQys4eOj35clLMXPJLpAY8YMBE4EXZDAGI_ZfLajbAR2yeuCVRTXlOJp6hQBu_6rtpbC1anydaTj3anhSPxNTrXuK4JIBrMY970KPwmCfnzY4-PhTFXTDadwFSXRFfGfLV2APbPdglRSgo70GpFMjLT9B_Jkaha-5iyfK9wJDqd5NBOaYZMqeN4inxFkXJ4VBLGuIktR6pwGGwk9FKC50K7tyhwjSUfEVZyNdKW6uDyR0yUEdzpJfEYQthaxGu341n2ZNTsyMLAJH4Y9eAlbnv8kivfOH7Qdwf9tOUf8BiVBxu6H3Y5Pdi79HwCJgcho7uzUKrk0w85XPQA&isca=1" alt="" className="w-[350px] mx-4 my-4" />
          <img src="https://ff5164f1f7b54188ca38bb56d5a47ea0c9dd9a5a85d60eb4e594f07-apidata.googleusercontent.com/download/storage/v1/b/searchapp-25415.appspot.com/o/EMMA-LANDSCAPING-coupon-1.png?jk=AahUMltUVLUbPWHavdQKOF1wrFGzDRaSJnZX3b5dXlFvCSdRu1w1YoRkA0eDyuz_1qoCo56GtY5FnC1T_u3C5tqot-B28zk6lfMA0oQRwGUlSfrBoq7Bg9lCJpoxuQRmGunrxKnzG6wISH0nzLaEVe1zLWmqCCqRCsP4nPx_pbLrMigWZWeIerNH96I2QPqbRv187bVDWz5lskOvWagzdZ0BcNQSlVqvoT_SsuAaZ5WHcHclBRUVJZNLI7OUi4LcowX2hcZqPUO3AV1ElbL0Rl31AdBkcUrjZhPnx1gl1qzq0I-yU-UvCCKAxnw5FbS66xZiM-zc9uZ1Fa6PUVDNZISBRvwoGjcqUUd9t8KWNejPtOsbng74o-VEDwRMv_N2eIUlvQzz-hGCjYncYz1FjBIbDYh4Ed2Sq2UOQNuu6iZLWlCrS4efvu3eNR-A2B6c2QBw2nGRIDiNSXgKygvAInOLdqrgJaERjgh-uS_mq1THiWvkKmkwCi4OXueP4NkzTOjBps9ciG_-36Zk6CA_rKUZ2zb75AAvieYAcu8T0HQ_z5zu6d4ZrRuWorgg_LKdqV1niiFQsBapyQlr76KC6Kp-0H6HVB9veRMidzref8q12eUORHAoxc2EtRj00ohpqK6vPuTDfLGJ_TtCeW0fqNFSl3Js8kG25KWPjqGyCiUbDO0GqD3iyHyP_5TW-PWPVOLeLtBswJMcA6F4KvKOhv32iyHvkwV0DEl17D-bYZ18EFC1rovUDLTGdCHYTlDysP_qC6Axc2XP4lrWXGQY_ViHFIOM0a02NHhFtaWgdimSMM_1NQw4uvNT2w-nG3Ve63moMkmFlDOqMH11x7-CqsEU_Xlh7JMqcFf28m0RJw36AxseuSayTJWHM9AmOKeTTBJi93ENErs82QcLRqKG7D1T6at5t9jQz02gUWARnFJN7Kyax8xJQkdEkUbi9Dyz1fyVBgN55_nMgWFkPGxzcS_S8Jeam4InBs-_1BmM_fUPLJwzOgVGFfXriaZKFi2cgKZdXFv_SwDZD_tm-7UYEjSFto2c9miD1bcxSpbvwPQNnjJ09EaqqwwLpcdXe8AtA9jb8KQN6U2PgjAI8XNByq5AusMdgvLIDZkMqvugfdPigaV4iAXk15kKb-zQxfmaUijC1vIqBODNcUKrGXetXEola6wJWD670_rr8B24tq_0L4NdDSYF7-rkvXr0iIPuRNiSNPq0CN7fe9spSavBc4Qk35c5lKA1NHcjyG4MNuxUyQnMUxhqABiPd1t7adtrsRvLusu1TdXh6UH4tsmB7zEvINoLjEsbHshA7N-RnCfOMB1nRQluzsJ6jSRFu2f_UQSxYS_QrTLcT5LtAoB9M9m6OwPAW8ha8ckKKc--ZOEx59HXVJAv6ejV3BTTA3X1Ss4J3_EHUvWOBoczG68e063mxps9b6EUSYK2A0Hh7Y8vcbQTrufPk1nd_MSVVA&isca=1" alt="" className="w-[350px] mx-4 my-4" />
        </div>

        <Directories />

        <BlockSecondary
          title={rpdata?.dbSlogan?.[3]?.slogan}
          text={rpdata?.dbHome?.[1]?.text}
          image1={rpdata?.stock?.[8]}
          image2={rpdata?.stock?.[9]}
          image3={rpdata?.stock?.[10]}
        />

        <CounterUp image={rpdata?.stock?.[7]} />

        <BlockAbout
          title={'a little about us'}
          text={rpdata?.dbAbout?.[0]?.text}
          image={rpdata?.stock?.[3]}
          listsAbout={true}
        />

        {/* our reviews */}
        {
          rpdata?.reviews?.isHomeOnly === true ?
            <div className="w-4/5 mx-auto py-[100px]">
              <h1 className="pb-10 text-center">{rpdata?.labels?.general?.titleReviews}</h1>
              <div className={`${rpdata?.reviews?.links?.[0]}`}></div>
            </div>
            : null
        }

        {/* Servicios del home */}
        {
          rpdata?.simpleWidgets?.[3]?.activo ?
            <div className="pt-20">
              <ContentServices />
            </div>
            :
            <ServicesHome />

        }

        {/* Gallery cuando es OnePages */}

        {/* Paleta de Colores */}
        {
          rpdata?.simpleWidgets?.[3]?.activo ?
            <div className="pt-10 pb-28">
              <h2 className="text-center pb-[50px]">{rpdata?.labels?.general?.titleGallery}</h2>
              <GalleryContent galleryImages={rpdata?.gallery?.length > 0 ? rpdata?.gallery : rpdata?.stock} />
            </div>
            :
            null

        }
        {
          rpdata?.brandingExtra?.[0]?.activo ?
            <Paletacolor />
            : null
        }
        <div
          className="bgFormHome"
          style={{ backgroundImage: `url("${rpdata?.stock?.[8]}")` }}
        >
          <div className="relative">
            <FormHome />
          </div>
        </div>

        <Map />

      </div>
    </BaseLayout>
  );
}

export default Home;
